import React, {Component} from 'react';
import './Tutorial.css';
import {Container, Grid, Typography} from '@material-ui/core';
import * as config from "../../config/config";

import downloadWalletIcon from "../../assets/images/downloadWallet.svg";
import downloadWalletIconOff from "../../assets/images/downloadWalletoff.svg";
import verifyIcon from "../../assets/images/verifyIDIcon.svg";
import verifyIDOff from "../../assets/images/verifyIDoff.svg";
import governmentIcon from "../../assets/images/governmentIcon.svg";
import governmentIconOff from "../../assets/images/governmentIconoff.svg";
import universityIcon from "../../assets/images/universityIcon.svg";
import universityIconOff from "../../assets/images/universityIconoff.svg";

import HeaderTutorial from '../../components/HeaderTutorial/HeaderTutorial';
import Panel from '../../components/Panel/Panel';
import {RestartButton} from '../../components/RestartButton/RestartButton';


interface Props {
  location: any;
}

interface State {
  step: number;
  isProcessEnded: boolean;
}



class Tutorial extends Component<Props, State> {
  step0Ref;
  step1Ref;
  step2Ref;
  step3Ref;
  step4Ref;

  constructor(props: any) {
    super(props);
    this.state = {
      step: +(sessionStorage.getItem("step") || 0),
      isProcessEnded: false
    };
    this.step0Ref = React.createRef();
    this.step1Ref = React.createRef();
    this.step2Ref = React.createRef();
    this.step3Ref = React.createRef();
    this.step4Ref = React.createRef();

    this.continue = this.continue.bind(this);
  }

  componentDidMount() {
    const code = new URLSearchParams(this.props.location.search).get("step");
    this.setState({
      step: 0,
    });
    if(code){
      const intCode: number = parseInt(code);
      this.setState({
        step: intCode,
      });
      this.changeFocus(intCode);
    }
    const codeFromStorage = sessionStorage.getItem("step");
    if(codeFromStorage){
      const intCode: number = parseInt(codeFromStorage);
      this.setState({
        step: intCode,
      });
      this.changeFocus(intCode);
    }
    sessionStorage.setItem("tutorial", "true");
  }

  continue(){
    const {step} = this.state;
    sessionStorage.setItem("step", String(step+1))
    this.redirectTo(step);
  }

  changeFocus(code:number){
    if(code === 1 ) this.step1Ref.current?.scrollIntoView();
    if(code === 2 ) this.step2Ref.current?.scrollIntoView();
    if(code === 3 ) this.step3Ref.current?.scrollIntoView();
    if(code === 4){
      this.setState({
        isProcessEnded: true,
      });
      this.step4Ref.current?.scrollIntoView();
    }
  }

  redirectTo(step: number){
    if(step === 0){
      window.location.replace("/demo/tutorial?step=1");
      this.setState({
        step: 1,
      });
    }
    if(step === 1){
      const currentUrl = window.location.href
      const nextStepUrl = currentUrl.replace("step=1", "step=2")
      window.location.replace(`${config.VIDISSUER_URL}?redirect_url=${nextStepUrl}`);
    }
    if(step === 2){
      window.location.replace(config.GOVERNMENT_URL);
    }
    if(step === 3){
      window.location.replace(config.UNIVERSITY_URL);
    }
    if(step === 4){
      this.setState({
        isProcessEnded: true,
      });
    }
  }

  restart(){
    this.setState({
      step: 0,
    });
    sessionStorage.setItem("step", String(0));
    window.location.replace("/demo/tutorial");
  }
render() {
  const {step,isProcessEnded} = this.state;
  return (
    <Container>
      <HeaderTutorial />
      <Grid container
        direction="column"
        justify="center"
        alignItems="center"
        className="content">
          <Grid item className="titleHome">
            <Typography variant="h3"><b>{"Test VIDidentity user journey demo by taking the following steps in order"}</b></Typography>
          </Grid>
          <Grid container
            direction="column"
            justify="space-between"
            alignItems="center"
            className="panels">
            <Panel
                title="Download VIDwallet"
                panelText="Find VIDwallet in GooglePlay just clicking on VIDwallet icon. The application is available for Android in the Play Store and for iOS in the App Store."
                stepPanel={0}
                stepSelected={step}
                iconOn={downloadWalletIcon}
                iconOff={downloadWalletIconOff}
                textButton="Next"
                refPanel={this.step0Ref}
                functionClickButton={this.continue}/>
            <Panel
                title="Obtain your first Verifiable Credential"
                panelText="Once you have installed VIDwallet, you are ready to issue your first credential. Navigate to the VIDissuer page to issue an EmailCredential. It is important that you select this type, as it will be needed in the following steps."
                stepPanel={1}
                stepSelected={step}
                iconOn={verifyIcon}
                iconOff={verifyIDOff}
                textButton="Go to VIDissuer"
                refPanel={this.step1Ref}
                functionClickButton={this.continue}/>
            <Panel
                title="Government of Freedonia"
                panelText="Go to Government of Freedonia and sign in using the Verifiable eID that you hold in your wallet. Once you have accesed to the portal you can request the Large Familiy credential that you can use in other entities to apply for discounts."
                stepPanel={2}
                stepSelected={step}
                iconOn={governmentIcon}
                iconOff={governmentIconOff}
                textButton="Go to Freedonia"
                refPanel={this.step2Ref}
                functionClickButton={this.continue}/>

            <Panel
                title="ACME University"
                panelText="Go to ACME University and sign in with VIDidentity once more. Here you can request a new credential, namely your Student card. To complete this tutorial, apply for a discount on your student fee presenting the Large Family credential you already hold in your wallet."
                stepPanel={3}
                stepSelected={step}
                iconOn={universityIcon}
                iconOff={universityIconOff}
                textButton="Go to Acme University"
                refPanel={this.step3Ref}
                functionClickButton={this.continue}/>
          </Grid>

          <Grid item className="restartProcess" ref={this.step4Ref}>
              {isProcessEnded &&
                <>
                  <Typography variant="subtitle1" className="bodyText">
                  {'You have successfully concluded the VIDidentity demo!'}
                  </Typography>
                  <Typography variant="subtitle1" className="bodyText">
                  {'You may restart the process if you wish so by clicking the button below:'}
                  </Typography>
                </>
              }
              <RestartButton variant="contained" onClick={()=> this.restart()} className="restartButton">
                  {"Restart process"}
              </RestartButton>
          </Grid>
      </Grid>


    </Container>
  );
  }
}


export default Tutorial
